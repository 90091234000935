export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `網站有新的版本了，可能是新集數，也可能是有新的酷東西（？）` +
        `要更新嗎？`
    )
  
    if (answer === true) {
      window.location.reload()
    }
  }

export const registerServiceWorker = () => true